import {
  Box,
  breakpoints,
  Button,
  ButtonLink,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  MotionBox,
  ProBadgeIcon,
  Text,
  ThumbnailCard,
  ThumbnailImage,
  useIsSmallScreen,
} from '@playful/design_system';
import { addErrorToast } from '@playful/workbench/components/AppToasts';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';

import { HStack, ModalCloseButton, Spinner } from '@chakra-ui/react';
import { ProjectInfo, ProjectSnapshot, ResourceUrlOptions, getResourceUrl } from '@playful/runtime';
import { getSnapshots } from '@playful/workbench/api/projects';
import { PREVIEW } from '@playful/workbench/project/resources';
import { CTAButton } from '../components/CallToAction/CTAButton';
import { useUserProjects } from '../hooks/useProjects';
import { useRouter } from '../hooks/useRouter';
import { useUserContext } from '../user/UserContext';
import { ProjectThumbnailLabel } from './ProjectThumbnailCard';

export function VersionsDialog({
  isOpen,
  onClose,
  projectInfo,
}: Pick<ModalProps, 'isOpen' | 'onClose'> & { projectInfo: ProjectInfo }) {
  const [selectedSnapshot, setSelectedSnapshot] = useState<ProjectSnapshot>();
  const { user, hasActiveSubscription } = useUserContext();
  const { rollbackProject, duplicateProject } = useUserProjects(user.id);
  const isSmScreen = useIsSmallScreen();
  const { data: snapshots, isLoading } = useSWR(isOpen ? `snapshots/${projectInfo.id}` : null, () =>
    getSnapshots(projectInfo.id),
  );
  const { push } = useRouter();

  function onSelect(snapshot: ProjectSnapshot) {
    setSelectedSnapshot(snapshot);
  }

  function handleOnClose() {
    setSelectedSnapshot(undefined);
    onClose();
  }

  async function onRestore(): Promise<void> {
    try {
      await rollbackProject(projectInfo.id, selectedSnapshot!.project);
      onClose();
      push(`/edit/${projectInfo.id}`);
    } catch (err) {
      addErrorToast('Failed to restore project.');
    }
  }

  async function onDuplicate(): Promise<void> {
    try {
      await duplicateProject(projectInfo, {
        snapshotId: selectedSnapshot?.project,
        title: `${projectInfo.title} (copy of ${localTimeStringFromUTC(
          selectedSnapshot!.timestamp,
        )})`,
      });
      onClose();
    } catch (err) {
      addErrorToast('Failed to duplicate project.');
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      isCentered
      scrollBehavior={'inside'}
      size={isSmScreen ? 'full' : 'fullish'}
    >
      <ModalOverlay />
      <ModalContent minH={'50vh'} height='auto' mx={15}>
        <ModalHeader pt={6} fontSize={'3xl'}>
          versions: {projectInfo.title}
        </ModalHeader>
        <ModalCloseButton bg='gray.50' right={6} top={6} />
        <ModalBody overflowY='auto' maxH={'65vh'} display={'flex'} p={10}>
          {isLoading ? (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Spinner />
            </div>
          ) : (
            <SnapshotList
              snapshots={snapshots}
              selectedSnapshot={selectedSnapshot}
              onSelect={onSelect}
              onRestore={onRestore}
            />
          )}
        </ModalBody>
        <ModalFooter
          bg='white'
          justifyContent={'flex-start'}
          boxShadow={!snapshots || snapshots?.length === 0 ? 'none' : 'lg-up'}
          px={10}
          py={4}
        >
          <HStack justifyContent='space-between' w='100%'>
            <Button onClick={handleOnClose}>cancel</Button>
            <HStack gap={4}>
              {hasActiveSubscription ? (
                <>
                  <Button
                    isDisabled={selectedSnapshot === undefined || !hasActiveSubscription}
                    onClick={onDuplicate}
                  >
                    duplicate
                  </Button>
                  <CTAButton
                    colorScheme='yellow'
                    isDisabled={selectedSnapshot === undefined || !hasActiveSubscription}
                    onClick={onRestore}
                  >
                    restore & open
                  </CTAButton>
                  <ProBadgeIcon
                    fill={hasActiveSubscription ? 'gray.500' : 'purple.500'}
                    h={6}
                    w={6}
                  />
                </>
              ) : (
                <>
                  <Text>Restore a previous version when you upgrade to Hatch Pro</Text>
                  <ButtonLink
                    colorScheme='yellow'
                    leftIcon={<ProBadgeIcon fill='gray.700' />}
                    href='/pricing'
                  >
                    upgrade to pro
                  </ButtonLink>
                </>
              )}
            </HStack>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

type VersionListProps = {
  snapshots: ProjectSnapshot[] | undefined;
  selectedSnapshot: ProjectSnapshot | undefined;
  onSelect: (snapshot: ProjectSnapshot) => void;
  onRestore: () => void;
};

const SnapshotList = ({ snapshots, selectedSnapshot, onSelect, onRestore }: VersionListProps) => {
  // TODO: Taken from TemplateShowcase.tsx, should be reusable/shared
  const [containerWidth, setContainerWidth] = useState(0);

  // How many thumbnails to fit on a row
  const pageSize = useMemo(() => {
    if (containerWidth < breakpoints.lg) return 2;
    if (containerWidth < breakpoints.xl) return 4;
    if (containerWidth < breakpoints['2xl']) return 4;
    return 5;
  }, [containerWidth]);

  // Gap in between thumbnails
  const gap = useMemo(() => {
    if (containerWidth < breakpoints.md) return 16;
    return 24;
  }, [containerWidth]);

  const eachSize = useMemo(
    () => (containerWidth - (pageSize - 1) * gap) / pageSize,
    [pageSize, containerWidth, gap],
  );

  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const width = entry.contentRect.width;
        setContainerWidth(width);
      }
    });
    const container = containerRef.current;
    if (container) {
      observer.observe(container);
    }

    return () => {
      if (container) {
        observer.unobserve(container);
      }
    };
  }, []);

  return (
    <MotionBox
      ref={containerRef}
      w='100%'
      position='relative'
      initial={{ top: '-16px', opacity: 0 }}
      animate={{ top: '0px', opacity: 1 }}
      transition={{
        type: 'spring',
        mass: 1,
        stiffness: 100,
        damping: 35,
      }}
      display={'flex'}
      flexDirection='column'
      bg={!snapshots || snapshots?.length === 0 ? 'gray.50' : undefined}
    >
      {(!snapshots || snapshots.length === 0) && (
        <Box textAlign='center' h='100%' p={8} alignContent={'center'}>
          <Text>This project does not have any version history</Text>
        </Box>
      )}
      <Box display='flex' gap={`${gap}px`} width='100%' flexWrap='wrap'>
        {snapshots &&
          snapshots
            ?.slice()
            .reverse()
            .map((snapshot) => (
              <SnapshotItem
                width={eachSize}
                key={snapshot.project}
                snapshot={snapshot}
                selected={snapshot === selectedSnapshot}
                onSelect={onSelect}
                onRestore={onRestore}
              />
            ))}
      </Box>
    </MotionBox>
  );
};

const THUMBNAIL_SIZE = 480;

const SnapshotItem = ({
  snapshot,
  selected,
  onSelect,
  onRestore,
  width,
}: {
  snapshot: ProjectSnapshot;
  selected: boolean;
  onSelect: (snapshot: ProjectSnapshot) => void;
  onRestore: (snapshot: ProjectSnapshot) => void;
  width: number;
}) => {
  const { authToken } = useUserContext();

  const timeString = formatVersionDate(snapshot.timestamp);
  let description;
  switch (snapshot.type) {
    case 'migration':
    case 'auto':
      description = 'auto save';
      break;

    case 'restore':
      description = 'restore';
      break;

    default:
      description = snapshot.type;
      break;
  }

  const opts: ResourceUrlOptions = {
    transform: `rs:fit:${THUMBNAIL_SIZE}:${THUMBNAIL_SIZE}`,
  };

  // if we're showing a private project to the project's owner attach their auth token.
  // add it this was to avoid `auth=undefined` on public requests
  opts['auth'] = authToken;
  const previewUrl = snapshot.project && getResourceUrl(snapshot.project, PREVIEW, '', opts);

  return (
    <Box>
      <ThumbnailCard
        Thumbnail={<ThumbnailImage src={previewUrl} border='none' />}
        ThumbPreviewBoxProps={{
          backgroundColor: selected ? 'gray.100' : 'inherit',
          borderWidth: selected ? 2 : 1,
          borderColor: selected ? 'blue.500' : 'gray.100',
          borderRadius: 'sm',
        }}
        w={width}
        objectFit={'scale-down'}
        p={2}
        onClick={() => onSelect(snapshot)}
        onDoubleClick={() => onRestore(snapshot)}
      />
      <ProjectThumbnailLabel>
        <Text w='100%'>{timeString}</Text>
        <Text fontSize={'xs'}>{description}</Text>
      </ProjectThumbnailLabel>
    </Box>
  );
};

function formatVersionDate(utcString: string): string {
  const date = new Date(utcString);
  // get date from 1 year ago
  const yearAgo = new Date();
  yearAgo.setFullYear(yearAgo.getFullYear() - 1);
  if (date > yearAgo) {
    // format date like 'Jan 1, 12:00 pm'
    return date.toLocaleString(undefined, {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  // format date like 'Jan 1, 2023 12:00 pm'
  return localTimeStringFromUTC(utcString);
}

function localTimeStringFromUTC(utcString: string): string {
  const date = new Date(utcString);
  return date.toLocaleString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}
