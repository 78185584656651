import type { ProjectInfo, Properties } from '@playful/runtime';
import { Resource } from '@playful/workbench/project/resources';
import sanitize from 'sanitize-filename';

import { validateIdentifier } from '../utils/validateMisc';

// TODO: progress indicator
export async function exportProject(projectInfo: ProjectInfo): Promise<void> {
  const exportDir = await (window as any).showDirectoryPicker();

  // Create project dir.
  const title = sanitize(projectInfo.title);
  const projectDir = await exportDir.getDirectoryHandle(title, { create: true });

  let projectJson: string | undefined;

  // Write all resources.
  const resource = await Resource.get(projectInfo.project);
  // TODO: "keys" doesn't seem to be the right name for this.
  for (const resData of resource.keys) {
    const url = resource.getDataUrl(resData.key);
    try {
      const response = await fetch(url);
      const buffer = await response.arrayBuffer();

      const outPath = resData.key.split('/');
      const outFile = outPath.pop();
      let outDir = projectDir;
      while (outPath.length > 0) {
        const dirName = outPath.shift();
        outDir = await outDir.getDirectoryHandle(dirName, { create: true });
      }

      const newFileHandle = await outDir.getFileHandle(outFile, {
        // TODO: Any way to write created and/or modified date?
        create: true,
      });
      const writable = await newFileHandle.createWritable();
      await writable.write(buffer);
      await writable.close();

      if (outFile === 'project.json') {
        projectJson = new TextDecoder('utf-8').decode(buffer);
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Write info.json.
  let newFileHandle = await projectDir.getFileHandle('info.json', { create: true });
  let writable = await newFileHandle.createWritable();
  await writable.write(JSON.stringify(projectInfo, null, 2));
  await writable.close();

  // Write source control friendly project.js.
  if (projectJson) {
    newFileHandle = await projectDir.getFileHandle('project.js', { create: true });
    writable = await newFileHandle.createWritable();
    await writable.write(getJavascriptSource(JSON.parse(projectJson)));
    await writable.close();
  }
}

function getJavascriptSource(projectState: Properties) {
  return 'export default ' + toJavascriptString(projectState, 0, { noNewline: true });
}

function toJavascriptString(
  obj: Properties,
  nesting = 0,
  options?: { noNewline?: boolean; filter?: Properties[] },
): string | undefined {
  const spaces = '  ';
  const indent = spaces.repeat(nesting);

  switch (typeof obj) {
    case 'object':
      if (Array.isArray(obj)) {
        // TODO: ReactorArray ids
        let isArrayOfObjects = false;
        const elements = obj.map((v) => {
          if (typeof v === 'object') {
            isArrayOfObjects = true;
          }
          return toJavascriptString(v, nesting + 1, options);
        });
        const body = elements.join(isArrayOfObjects ? ',' : ', ');
        const multiline = body && body.includes('\n');
        return '[' + body + (multiline ? `,\n${indent}` : '') + ']';
      } else {
        // Group and order non-object, arrays, objects but otherwise retain order.
        const other: Properties = {};
        const arrays: Properties = {};
        const objects: Properties = {};
        for (const property in obj) {
          const v = obj[property];
          if (typeof v === 'object') {
            if (Array.isArray(v)) {
              arrays[property] = v;
            } else {
              objects[property] = v;
            }
          } else {
            other[property] = v;
          }
        }
        obj = Object.assign(other, arrays, objects);

        // TODO: Dates, Regex
        const properties = Object.keys(obj)
          .map((property) => {
            const v = obj[property];
            // TODO: getters/setters, async
            const indent2 = indent + spaces;

            // Wrap identifier in quotes if it is invalid.
            const errors = validateIdentifier({ identifier: property }, 'submit');
            if (errors.identifier) {
              property = "'" + property + "'";
            }

            const objectSeparator = typeof v === 'object' && !Array.isArray(v) ? '\n' : '';
            return `${objectSeparator}${indent2}${property}: ${toJavascriptString(v, nesting + 1, {
              noNewline: true,
            })}`;
          })
          .join(',\n');
        const separator = options?.noNewline ? '' : `\n${indent}`;
        return `${separator}{\n${properties}${properties ? ',' : ''}\n${indent}}`;
      }

    case 'string':
      const s = obj as string;
      if (s.includes('\n')) {
        return `\`${s.replace(/`/gm, '\\`').replace(/\${/gm, '\\${')}\``;
      } else if (s.includes("'")) {
        return `"${s.replace(/"/gm, '\\"')}"`;
      }
      return `'${s.replace(/'/gm, "\\'")}'`;

    case 'number':
      return obj;

    case 'boolean':
      return (obj as boolean).toString();
  }

  return `unhandled type ${typeof obj}`;
}
