import {
  Alert,
  AlertDescription,
  AlertTitle,
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalProps,
  Spinner,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@playful/design_system';
import { fromPromise } from '@playful/utils';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useProject } from '../hooks/useProject';

export type RenameSchema = {
  projectName: string;
};

export const validationSchema = yup.object({
  projectName: yup.string().required('Project name is required'),
});

type RenameProjectModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  projectId?: string;
  onSuccess?: () => void;
  onError?: (err: any) => void;
};

function RenameProjectContent({ onClose, projectId, onSuccess, onError }: RenameProjectModalProps) {
  const [submissionErr, setSubmissionErr] = useState<string>();
  const { renameProject, projectInfo, isLoading } = useProject({
    id: projectId,
    onRename(err) {
      if (err) console.error(err);
      onClose();
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValidating, isSubmitting, isValid, isDirty },
  } = useForm<RenameSchema>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    reset({ projectName: projectInfo?.title });
  }, [reset, projectInfo?.title]);

  async function onSubmit({ projectName }: RenameSchema) {
    const [err] = await fromPromise(renameProject(projectName));

    if (err) {
      onError?.(err);
      return setSubmissionErr(err);
    }

    onSuccess?.();
    onClose();
  }

  return (
    <ModalContent as='form' w='340px' p={6} onSubmit={handleSubmit(onSubmit)}>
      <ModalBody p={0}>
        {!isLoading ? (
          <FormControl isInvalid={isDirty && !isValid}>
            <FormLabel m={0} fontSize='xl'>
              rename project
            </FormLabel>
            <Box py={4}>
              <Input
                isDisabled={isSubmitting}
                {...register('projectName')}
                onFocus={(event) =>
                  window.setTimeout(() => {
                    event.target.setSelectionRange(0, event.target.value.length, 'forward');
                  })
                }
              />
              <FormErrorMessage>a project name is required</FormErrorMessage>
            </Box>
          </FormControl>
        ) : (
          <Box w='100%' textAlign='center' py={4}>
            <Spinner />
          </Box>
        )}
        {submissionErr && (
          <Alert status='error' mb={4}>
            <AlertTitle>Oops!</AlertTitle>
            <AlertDescription>{submissionErr}</AlertDescription>
          </Alert>
        )}
      </ModalBody>
      <ModalFooter gap={4} p={0}>
        <Button variant='outline' flexGrow={1} onClick={onClose}>
          cancel
        </Button>
        <Button
          isLoading={isValidating || isSubmitting}
          isDisabled={isLoading || isValidating || isSubmitting || !!errors.projectName}
          type='submit'
          colorScheme='yellow'
          flexGrow={1}
        >
          done
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}

export function RenameProjectModal(props: RenameProjectModalProps) {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <RenameProjectContent {...props} />
    </Modal>
  );
}
