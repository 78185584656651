import type { TextProps } from '@chakra-ui/react';
import {
  Box,
  ThumbnailCard,
  ThumbnailCardLabel,
  ThumbnailCardSubtitle,
  ThumbnailImage,
  ThumbnailVideo,
} from '@playful/design_system';
import { ProjectInfo, hasPrivateContent } from '@playful/runtime';
import React from 'react';

import { useProject } from '../../hooks/useProject';
import { useProjectPreview } from '../ProjectPreview';
import type { ProjectThumbnailCardProps } from './types';

export function useProjectThumb({
  projectId,
  videoUrl,
  showStaticImage,
  projectInfo: fallbackData,
  showPublished,
}: {
  projectId?: string;
  videoUrl?: string;
  showStaticImage?: boolean;
  projectInfo?: ProjectInfo;
  showPublished?: boolean;
}) {
  const { projectInfo } = useProject({ id: projectId, fallbackData });
  const { previewUrl = '' } = useProjectPreview({
    projectInfo,
    showPublished,
  });

  const shouldUseVideo = videoUrl && !showStaticImage;

  return {
    type: shouldUseVideo ? 'video' : 'image',
    mediaUrl: shouldUseVideo ? videoUrl : previewUrl,
    poster: shouldUseVideo ? previewUrl : undefined,
  };
}

export const ProjectThumbnailCard = ({
  children,
  projectId,
  hoverLabel,
  objectFit,
  showPublished = true,
  Menu,
  hasGradientOverlay = false,
  isMenuOpen,
  fallbackData,
  showStaticImage,
  videoUrl,
  ...thumbProps
}: ProjectThumbnailCardProps) => {
  const { projectInfo } = useProject({ id: projectId, fallbackData });
  const { type, mediaUrl, poster } = useProjectThumb({
    projectId,
    videoUrl,
    showStaticImage,
    projectInfo: fallbackData,
    showPublished,
  });

  let thumbnailToUse;

  // Override Thumbnail with video if defined in mp4 previews
  if (type === 'video') {
    thumbnailToUse = (
      <ThumbnailVideo src={mediaUrl} poster={poster} title={projectInfo?.title} height={'100%'} />
    );
  } else if (mediaUrl) {
    thumbnailToUse = <ThumbnailImage src={mediaUrl} alt={projectInfo?.title} height={'100%'} />;
  }

  if (!projectInfo) return null;

  return (
    <ThumbnailCard
      Thumbnail={thumbnailToUse}
      flexGrow={1}
      objectFit={objectFit}
      Subtitle={hoverLabel && <ThumbnailCardSubtitle>{hoverLabel}</ThumbnailCardSubtitle>}
      Menu={Menu}
      hasGradientOverlay={hasGradientOverlay}
      isMenuOpen={isMenuOpen}
      data-cy='project-card'
      data-cy-published={!!projectInfo.published}
      data-cy-owner={projectInfo.owner}
      data-cy-title={projectInfo.title}
      data-cy-id={projectInfo.id}
      data-private={hasPrivateContent(projectInfo) ? true : undefined}
      version={projectInfo.version}
      {...thumbProps}
    >
      {children}
    </ThumbnailCard>
  );
};

export function ProjectThumbnailLabel({ children, ...props }: TextProps) {
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      mt={2}
      gap={1}
      alignItems='flex-start'
      wordBreak='break-all'
      {...props}
    >
      <ThumbnailCardLabel>{children}</ThumbnailCardLabel>
    </Box>
  );
}
