import { useCallbackRef } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useUserContext } from '../user/UserContext';
import { useRouter } from './useRouter';

/**
 * When the provided handler is called, it will check `isLoggedIn` or `isAuthenticated`,
 * and if either is true, it will execute the handler.
 *
 * If both are false, it will redirect to the public signup/signin entrypoint.
 */
export function useProtectedHandler(handler: (...args: any[]) => void, isAuthenticated = false) {
  const { isLoggedIn } = useUserContext();
  const { asPath, push } = useRouter();
  const cachedHandler = useCallbackRef(handler);
  const protectedHandler = useCallback(
    (...args: any[]) => {
      if (isLoggedIn || isAuthenticated) return cachedHandler(...args);

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.append('next', asPath);

      const newSearch = searchParams.toString();
      const redirectPath = `/register${newSearch && '?' + newSearch}`;

      return push(redirectPath);
    },
    [cachedHandler, isAuthenticated, isLoggedIn, push, asPath],
  );

  return { handler: protectedHandler };
}
