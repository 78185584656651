import { Wrap } from '@chakra-ui/react';
import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Tag,
  TagCloseButton,
  TagLabel,
} from '@playful/design_system';
import type { Tags } from '@playful/runtime';
import { withF, fromPromise } from '@playful/utils';
import { addErrorToast } from '@playful/workbench/components/AppToasts';
import React, { useEffect, useState } from 'react';

import { CTAButton } from '../components/CallToAction/CTAButton';
import { useProject } from '../hooks/useProject';

// These tags are hidden from the user
const internalTags: string[] = [];

type TagsDialogProps = Pick<ModalProps, 'isOpen' | 'onClose'> & { projectId?: string };

function TagsContent({ onClose, projectId }: TagsDialogProps) {
  const [input, setInput] = useState('');
  const { projectInfo, setTags: setProjectTags } = useProject({ id: projectId });
  const [tags, setTags] = useState<string[]>([]);

  async function handleEditTagConfirm(tags: Tags): Promise<void> {
    if (!projectInfo) return;

    const [err] = await fromPromise(setProjectTags(tags));

    if (err) addErrorToast('Failed to update tags.');
    onClose();
  }

  useEffect(() => {
    if (!projectInfo) return;

    const { tags } = projectInfo;

    if (!tags) return;

    setTags(
      Object.keys(tags)
        .filter((tag) => !internalTags.includes(tag)) // Filter out internal tags
        .filter((tag) => tags[tag]),
    ); // Filter any 'false' tags. Ideally, this wouldn't happen, but TypeScript only goes so far
  }, [projectInfo]);

  const addTag = (tag: string) => {
    if (tag.length < 0 || tags.includes(tag)) return;

    setTags((tags) => [...tags, tag]);
    setInput('');
  };

  // Thanks https://stackoverflow.com/a/59038435/707320.
  const onKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Tab':
      case 'Enter':
      case ',':
      case ' ': {
        event.preventDefault();
        event.stopPropagation();
        addTag(input);
        break;
      }
      default:
    }
  };

  return (
    <ModalContent maxWidth={'460px'} mx={4}>
      <ModalHeader pt={6}>Add tags to {projectInfo?.title}</ModalHeader>
      <ModalBody>
        <InputGroup size='md'>
          <Input
            type={'text'}
            variant={'flat'}
            placeholder='enter tags'
            pr={3}
            onKeyDown={onKeyDown}
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <InputRightElement width='5.5rem'>
            <Button
              h='1.75rem'
              size='sm'
              color='gray.700'
              variant={'ghost'}
              mr={2}
              onClick={() => addTag(input)}
            >
              + add tag
            </Button>
          </InputRightElement>
        </InputGroup>
        <Wrap spacing={2} pt={4} pb={0}>
          {tags.map((tag) => (
            <Tag key={`tag-${tag}`} size={'lg'} variant={'solid'}>
              <TagLabel>{tag}</TagLabel>
              <TagCloseButton onClick={() => setTags((tags) => tags.filter((t) => t !== tag))} />
            </Tag>
          ))}
        </Wrap>
      </ModalBody>
      <ModalFooter gap={4} pb={6}>
        <Button variant={'outline'} w={'100%'} onClick={withF(onClose)} borderColor={'gray.200'}>
          cancel
        </Button>
        <CTAButton
          colorScheme='yellow'
          w={'100%'}
          // TODO: check with ux on the case of typing a tag and clicking save (no enter/tab/space/comma).
          onClick={() =>
            projectInfo?.tags !== undefined &&
            handleEditTagConfirm(processTags(projectInfo.tags, tags))
          }
        >
          save
        </CTAButton>
      </ModalFooter>
    </ModalContent>
  );
}

export function TagsDialog(props: TagsDialogProps) {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <TagsContent {...props} />
    </Modal>
  );
}

function processTags(oldTags: Tags, tags: string[]) {
  const newTags: Tags = {};

  tags
    .filter((tag) => !internalTags.includes(tag)) // Filter out internal tags
    .forEach((tag) => {
      newTags[tag] = true;
    });

  // Copy any internal tags from old -> new
  internalTags.forEach((tag) => {
    if (oldTags[tag]) {
      newTags[tag] = true;
    }
  });

  return newTags;
}
