import { RouteLink } from '@playful/design_system';
import React from 'react';

import { useProject } from '../../hooks/useProject';
import { buildUserRoute } from '../../paths';
import { PreviewThumbLink } from './PreviewCard';
import { ProjectThumbnailLabel } from './ProjectThumbnailCard';
import type { CardProps } from './types';

export function CommunityCard(props: CardProps & { hideLabel?: boolean }) {
  const { projectId, fallbackData, hideLabel } = props;
  const { projectInfo } = useProject({ id: projectId, fallbackData });

  if (!projectInfo) return null;

  return (
    <div>
      <PreviewThumbLink {...props} />
      {!hideLabel && (
        <RouteLink href={buildUserRoute(projectInfo.ownerName)}>
          <ProjectThumbnailLabel>{`@${projectInfo.ownerName}`}</ProjectThumbnailLabel>
        </RouteLink>
      )}
    </div>
  );
}
