import { Portal } from '@chakra-ui/react';
import { FEATURE_INTERNAL_TOOLS, FEATURE_PROJECT_TAGS } from '@playful/api';
import {
  Divider,
  DuplicateIcon,
  ExportIcon,
  LinkIcon,
  LockIcon,
  Menu,
  MenuItem,
  MenuList,
  OpenLinkIcon,
  PencilIcon,
  RouteLink,
  SettingsIcon,
  ShareIcon,
  TagsIcon,
  TrashIcon,
  WaitIcon,
  UnlockIcon,
  UserIcon,
  useGroupDisclosure,
  HStack,
  Box,
  ProBadgeIcon,
} from '@playful/design_system';
import type { ProjectInfo } from '@playful/runtime';
import { fromPromise, isClientCtx } from '@playful/utils';
import { addErrorToast, addSuccessToast } from '@playful/workbench/components/AppToasts';
import React, { PropsWithChildren, useRef } from 'react';

import { ConfirmDeleteDialog, useDeleteProject } from '../explorer/ConfirmDeleteDialog';
import { VersionsDialog } from '../explorer/VersionsDialog';
import { exportProject } from '../explorer/exportProject';
import { TagsDialog } from '../explorer/TagsDialog';
import { useProjectPermissions } from '../hooks/useProject';
import { useUserProjects } from '../hooks/useProjects';
import { useProtectedHandler } from '../hooks/useProtectedHandler';
import { buildUserRoute } from '../paths';
import { ProjectSettingsModal } from '../projectSettings/ProjectSettingsModal';
import { RenameProjectModal } from '../projectSettings/RenameProjectModal';
import { useProfileProject } from '../projectSettings/useProfileProject';
import { useProjectLinkCopy } from '../projectSettings/useProjectLinkCopy';
import { useUserContext } from '../user/UserContext';
import { useUser } from '../user/useUser';

export const ProjectMenu = ({
  children,
  projectInfo,
  isOpen,
  onOpen,
  onClose,
}: PropsWithChildren<{
  projectInfo: ProjectInfo;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}>) => {
  const { user, hasFlag, hasActiveSubscription } = useUserContext();
  const {
    onOpen: onModalOpen,
    isOpenById,
    onClose: onModalClose,
  } = useGroupDisclosure<'project-settings' | 'rename' | 'tags' | 'delete' | 'versions'>();
  const { user: currentUser } = useUser({ id: user.id, fallbackData: user });
  const { duplicateProject } = useUserProjects(currentUser?.id);
  const deleteProject = useDeleteProject(projectInfo.id);
  const containerRef = useRef<HTMLElement>(null);
  const isLocked = projectInfo?.permissions.locked;
  const canExport = hasFlag(FEATURE_INTERNAL_TOOLS) && (window as any).showDirectoryPicker;
  const canTag = hasFlag(FEATURE_PROJECT_TAGS);
  const isProfileProject =
    currentUser?.profileProject && currentUser.profileProject === projectInfo?.id;

  const handleSuccess = (message: JSX.Element | string) =>
    addSuccessToast(message, { position: 'top-right' });

  const handleError = (err: Error, msg: string) => {
    console.error(err);
    addErrorToast(msg, { position: 'top-right' });
  };

  const { onLinkCopy } = useProjectLinkCopy({
    projectId: projectInfo?.id,
    containerRef,
    onError: (err) => handleError(err, 'Failed to copy link.'),
    onCopied: () => handleSuccess('link copied!'),
  });

  const { setAsProfileProject, unsetProfileProject } = useProfileProject({
    id: projectInfo.id,
    onError: (err) => handleError(err, 'Failed to update user profile project.'),
    onSet: () =>
      handleSuccess(
        <>
          {projectInfo?.title} is now your profile.&nbsp;
          <RouteLink color='primary' underline='always' href={buildUserRoute(user.name)}>
            Check it out.
          </RouteLink>
        </>,
      ),
    onUnset: () => handleSuccess('Your profile is reset to the default.'),
  });

  const onProfileSet = () => {
    if (!currentUser) return;
    currentUser.profileProject === projectInfo.id ? unsetProfileProject() : setAsProfileProject();
  };

  const onView = () => {
    const url = projectInfo.publicUrl;
    window.open(url, projectInfo.id);
  };

  const { handler: onDuplicate } = useProtectedHandler(async () => {
    if (!currentUser) return;

    const [err] = await fromPromise(duplicateProject(projectInfo));

    if (err) handleError(err, 'Failed to copy project.');
  });

  const { setLocked } = useProjectPermissions({
    id: projectInfo.id,
  });
  const onToggleLock = async () => {
    const [err] = await setLocked(!projectInfo.permissions.locked);

    if (err) handleError(err, 'Failed to toggle lock.');
  };

  if (!isClientCtx()) return null;

  const versionMenu = (
    <>
      <Divider />
      <MenuItem
        data-cy='versions'
        onClick={(event) => onModalOpen('versions')}
        icon={<WaitIcon boxSize={4} />}
      >
        <HStack justifyContent='space-between'>
          <Box as='span'>versions</Box>
          <ProBadgeIcon fill={hasActiveSubscription ? 'gray.500' : 'purple.500'} h={4} w={4} />
        </HStack>
      </MenuItem>
    </>
  );

  return (
    <>
      <Menu isOpen={isOpen} onClose={onClose} onOpen={onOpen} isLazy>
        {children}
        <Portal>
          <MenuList p={0} minW='unset' boxShadow='lg' onClick={(e) => e.stopPropagation()}>
            <>
              {!projectInfo.publishedProject ? (
                <MenuItem
                  data-cy='project-settings'
                  onClick={() => onModalOpen('project-settings')}
                  icon={<ShareIcon boxSize={4} />}
                >
                  publish project
                </MenuItem>
              ) : (
                <MenuItem
                  data-cy='project-settings'
                  onClick={onLinkCopy}
                  icon={<LinkIcon boxSize={4} />}
                >
                  copy link
                </MenuItem>
              )}
              <Divider />
              <MenuItem
                data-cy='duplicate-project'
                onClick={onDuplicate}
                icon={<DuplicateIcon boxSize={4} />}
              >
                duplicate
              </MenuItem>
              <MenuItem
                data-cy='rename-project'
                onClick={() => onModalOpen('rename')}
                icon={<PencilIcon boxSize={4} />}
              >
                rename
              </MenuItem>
              {canTag && (
                <MenuItem onClick={() => onModalOpen('tags')} icon={<TagsIcon boxSize={4} />}>
                  add tags
                </MenuItem>
              )}
              {canExport && (
                <MenuItem
                  onClick={() => exportProject(projectInfo)}
                  icon={<ExportIcon boxSize={4} />}
                >
                  export
                </MenuItem>
              )}
              <MenuItem
                data-cy='lock-project'
                onClick={onToggleLock}
                icon={isLocked ? <UnlockIcon boxSize={4} /> : <LockIcon boxSize={4} />}
              >
                {isLocked ? 'unlock' : 'lock'}
              </MenuItem>
              {!!projectInfo.publishedProject && (
                <>
                  <Divider />
                  <MenuItem onClick={onView} icon={<OpenLinkIcon boxSize={4} />}>
                    view published
                  </MenuItem>
                  <MenuItem
                    data-cy='project-settings'
                    onClick={() => onModalOpen('project-settings')}
                    icon={<SettingsIcon boxSize={4} />}
                  >
                    publish settings
                  </MenuItem>
                </>
              )}
              <Divider />
              <MenuItem onClick={onProfileSet} icon={<UserIcon boxSize={4} />}>
                {isProfileProject ? 'stop featuring on profile' : 'feature on profile'}
              </MenuItem>
              {versionMenu}
              <Divider />
              <MenuItem
                isDisabled={isLocked}
                data-cy='delete-project'
                onClick={(event) => {
                  if (event.shiftKey) {
                    deleteProject();
                  } else {
                    onModalOpen('delete');
                  }
                }}
                icon={<TrashIcon boxSize={4} />}
              >
                delete
              </MenuItem>
            </>
          </MenuList>
        </Portal>
      </Menu>
      <ProjectSettingsModal
        isOpen={isOpenById('project-settings')}
        onClose={onModalClose}
        projectId={projectInfo.id}
        onSuccess={(msg = 'Success!') => handleSuccess(msg)}
        onError={(msg = 'Something went wrong...') => addErrorToast(msg)}
        origin='my-projects'
      />
      <RenameProjectModal
        isOpen={isOpenById('rename')}
        onClose={onModalClose}
        projectId={projectInfo.id}
        onSuccess={() => handleSuccess('project renamed successfully')}
        onError={(err) => handleError(err, 'failed to rename project.')}
      />
      <ConfirmDeleteDialog
        isOpen={isOpenById('delete')}
        onClose={onModalClose}
        projectId={projectInfo.id}
      />
      <VersionsDialog
        isOpen={isOpenById('versions')}
        onClose={onModalClose}
        projectInfo={projectInfo}
      />
      {canTag && (
        <TagsDialog isOpen={isOpenById('tags')} onClose={onModalClose} projectId={projectInfo.id} />
      )}
    </>
  );
};
