import { useProject } from '../hooks/useProject';
import { useUserContext } from '../user/UserContext';
import { useUser } from '../user/useUser';

export function useProfileProject({
  id,
  onError,
  onSet,
  onUnset,
}: {
  id?: string;
  onError?: (err: any) => void;
  onSet?: () => void;
  onUnset?: () => void;
}) {
  const { setPublished, projectInfo } = useProject({ id });
  const { user } = useUserContext();
  const { user: currentUser, updateUser } = useUser({ id: user.id, fallbackData: user });

  async function setAsProfileProject() {
    if (!currentUser || !projectInfo) return;

    if (!projectInfo.published) {
      const [err] = await setPublished(true);

      if (err) return onError?.(err);
    }

    const [err] = await updateUser({
      profileProject: id,
    });

    if (err) return onError?.(err);

    onSet?.();
  }

  async function unsetProfileProject() {
    if (!currentUser) return;

    const [err] = await updateUser({
      profileProject: '',
    });

    if (err) return onError?.(err);

    onUnset?.();
  }

  return {
    setAsProfileProject,
    unsetProfileProject,
  };
}
