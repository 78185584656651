import { ModalCloseButton, ModalProps } from '@chakra-ui/react';
import {
  FlexModalContent,
  Modal,
  ModalBody,
  ModalOverlay,
  isSmallScreen,
} from '@playful/design_system';
import React from 'react';

import {
  PROJECT_SETTINGS_MAX_WIDTH,
  ProjectSettings as ProjectSettingsForm,
  ProjectSettingsProps,
} from './ProjectSettings';

type SettingsModalProps = Omit<ProjectSettingsProps, 'projectResource'>;

export function ProjectSettingsModal({
  isOpen,
  onClose,
  projectId,
  ...props
}: Pick<ModalProps, 'isOpen' | 'onClose'> & SettingsModalProps) {
  const isSmScreen = isSmallScreen();

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior={'inside'}>
      <ModalOverlay />
      <FlexModalContent
        m={4}
        modalPos={isSmScreen ? 'bottom' : 'top'}
        maxW={PROJECT_SETTINGS_MAX_WIDTH}
      >
        <ModalCloseButton
          variant={'ghost'}
          backgroundColor={'transparent'}
          _hover={{ backgroundColor: 'gray.400' }}
          right={2}
          aria-label='Close project settings'
        />
        <ModalBody p={0}>
          <ProjectSettingsForm
            isOpen={isOpen}
            projectId={projectId}
            w='full'
            onClose={onClose}
            {...props}
          />
        </ModalBody>
      </FlexModalContent>
    </Modal>
  );
}
