import React from 'react';

import { useProject } from '../../hooks/useProject';
import { buildProjectEditRoute } from '../../paths';
import { ProjectThumbnailCard, ProjectThumbnailLabel } from './ProjectThumbnailCard';
import type { CardProps } from './types';

export function EditCard({ ThumbLinkProps, ...props }: CardProps) {
  const { projectId, fallbackData } = props;
  const { projectInfo } = useProject({ id: projectId, fallbackData });

  if (!projectInfo) return null;

  return (
    <div>
      <ProjectThumbnailCard
        ThumbLinkProps={{
          href: buildProjectEditRoute(projectInfo),
          ...ThumbLinkProps,
        }}
        data-cy-primary-action='edit-project'
        showPublished={false}
        {...props}
      />
      <ProjectThumbnailLabel>{projectInfo.title}</ProjectThumbnailLabel>
    </div>
  );
}
