import { useBreakpointValue } from '@chakra-ui/react';
import type { User } from '@playful/api';
import { Box, IconButton, LockIcon, MenuButton, MoreVerticalIcon } from '@playful/design_system';
import type { ProjectInfo } from '@playful/runtime';
import React, { Fragment, PropsWithChildren } from 'react';

import { useProject } from '../../hooks/useProject';
import { buildProjectEditRoute } from '../../paths';
import { ProjectMenu } from '../../projectMenu/ProjectMenu';
import { useUserContext } from '../../user/UserContext';
import useDisclosure from '../../utils/useDisclosure';
import { ProjectThumbnailCard, ProjectThumbnailLabel } from './ProjectThumbnailCard';
import type { CardProps } from './types';
import { MediaOverlay } from '../../workbench/content/MediaOverlay';

// User Thumbnail card for use in Recent Projects, My Projects, etc. which has the project options menu
export function UserCard({
  isBulkModeEnabled,
  isBulkSelected,
  onBulkSelection,
  ThumbLinkProps,
  ...props
}: CardProps & {
  isBulkSelected?: boolean;
  onBulkSelection?: () => void;
  isBulkModeEnabled?: boolean;
}) {
  const { projectId } = props;
  const { user } = useUserContext();
  const { projectInfo } = useProject({ id: projectId });
  const { onOpen, isOpen, onClose } = useDisclosure();
  const buttonSize = useBreakpointValue({
    base: 'xs',
    md: 'sm',
  });

  if (!projectInfo) return null;

  const modified = timeAgo(projectInfo.modified);
  const isValidBulkEnabled = isBulkModeEnabled && !projectInfo.permissions.locked;

  return (
    <div>
      <BulkActionWrapper
        isBulkModeEnabled={!!isValidBulkEnabled}
        isSelected={!!isBulkSelected}
        onSelect={onBulkSelection}
      >
        <ProjectThumbnailCard
          data-cy-primary-action='edit-project'
          hoverLabel={modified}
          showPublished={false}
          isMenuOpen={isOpen}
          ThumbLinkProps={{
            href: buildProjectEditRoute(projectInfo),
            ...ThumbLinkProps,
          }}
          version={projectInfo.version}
          isBulkModeEnabled={isValidBulkEnabled}
          Menu={
            !isValidBulkEnabled && (
              <ProjectMenu
                projectInfo={projectInfo}
                onOpen={onOpen}
                onClose={onClose}
                isOpen={isOpen}
              >
                <MenuButton
                  as={IconButton}
                  position={'absolute'}
                  display={{ base: 'flex', md: 'none' }}
                  right={{ base: 1.5, md: 2 }}
                  top={{ base: 1.5, md: 2 }}
                  size={buttonSize}
                  variant={'solid'}
                  icon={<MoreVerticalIcon />}
                  backgroundColor={'gray.100'}
                  _hover={{ backgroundColor: 'white' }}
                  _groupHover={{ display: 'flex' }}
                  _active={{
                    display: 'flex',
                  }}
                  opacity={0.7}
                  sx={{
                    svg: {
                      w: { base: '4', md: '5' },
                      h: { base: '4', md: '5' },
                      color: 'gray.700',
                    },
                  }}
                  data-cy='project-menu'
                />
              </ProjectMenu>
            )
          }
          {...props}
        />
      </BulkActionWrapper>
      <MyProjectLabel projectInfo={projectInfo} user={user} />
    </div>
  );
}

function BulkActionWrapper({
  isBulkModeEnabled,
  onSelect,
  isSelected,
  ...props
}: PropsWithChildren<{ isBulkModeEnabled: boolean; onSelect?(): void; isSelected: boolean }>) {
  return isBulkModeEnabled ? (
    <MediaOverlay cursor='pointer' isSelected={isSelected} onClick={onSelect} {...props} />
  ) : (
    <Fragment {...props} />
  );
}

const MAX_TITLE_LENGTH = 67; // Roughly two lines of lower case text.

function MyProjectLabel({ projectInfo, user }: { projectInfo: ProjectInfo; user: User }) {
  let title = projectInfo.title;
  if (title.length > MAX_TITLE_LENGTH) {
    title = title.slice(0, MAX_TITLE_LENGTH) + '...';
  }

  if (projectInfo.permissions.locked && projectInfo.owner === user.id) {
    return (
      <ProjectThumbnailLabel>
        <Box as={'span'} display={'flex'} gap={1}>
          {title} <LockIcon flexShrink={0} boxSize={4} color='secondary' />
        </Box>
      </ProjectThumbnailLabel>
    );
  }
  return <ProjectThumbnailLabel>{title}</ProjectThumbnailLabel>;
}

// Thanks https://stackoverflow.com/a/72817357/707320!
function timeAgo(input: Date | number): string {
  const date = input instanceof Date ? input : new Date(input);
  const secondsElapsed = (date.getTime() - Date.now()) / 1000;

  // Only "ago" format if within a year.
  if (Math.abs(secondsElapsed) < 3600 * 24 * 365) {
    const ranges = [
      ['years', 3600 * 24 * 365],
      ['months', 3600 * 24 * 30],
      ['weeks', 3600 * 24 * 7],
      ['days', 3600 * 24],
      ['hours', 3600],
      ['minutes', 60],
      ['seconds', 1],
    ] as const;
    const formatter = new Intl.RelativeTimeFormat('en', { style: 'narrow' });

    for (const [rangeType, rangeVal] of ranges) {
      if (rangeVal < Math.abs(secondsElapsed)) {
        const delta = secondsElapsed / rangeVal;
        return formatter.format(Math.round(delta), rangeType);
      }
    }
  }

  return Intl.DateTimeFormat('en').format(date);
}
