import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@playful/design_system';
import { fromPromise } from '@playful/utils';
import { addErrorToast } from '@playful/workbench/components/AppToasts';
import React from 'react';

import { CTAButton } from '../components/CallToAction/CTAButton';
import { useUserProjects } from '../hooks/useProjects';
import { useProfileProject } from '../projectSettings/useProfileProject';
import { useUserContext } from '../user/UserContext';
import { useUser } from '../user/useUser';

const errMsg = 'Failed to delete project.';

export function useDeleteProject(projectId?: string): () => void {
  const { user } = useUserContext();
  const { user: currentUser } = useUser({ id: user.id });
  const { deleteProject } = useUserProjects(currentUser?.id);
  const { unsetProfileProject } = useProfileProject({
    id: projectId,
  });

  return async () => {
    if (!projectId) return;

    if (currentUser?.profileProject === projectId) {
      // if the deleted project was their profileProject, unset their profileProject
      unsetProfileProject();
    }
    const [err] = await fromPromise(deleteProject(projectId));

    err && addErrorToast(errMsg);
  };
}

export function ConfirmDeleteDialog({
  isOpen,
  onClose,
  projectId,
}: Pick<ModalProps, 'isOpen' | 'onClose'> & { projectId?: string }) {
  const deleteProject = useDeleteProject(projectId);

  async function onConfirmDeleteDialogClose(): Promise<void> {
    if (!projectId) return;

    deleteProject();

    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxWidth={'460px'} mx={4}>
        <ModalHeader pt={6}>Delete it forever?</ModalHeader>
        <ModalBody fontWeight={'normal'} fontSize={'1rem'}>
          If you delete this project it will be gone forever. If the project has been published, any
          links to it will break. It will be removed from the gallery and your profile.
        </ModalBody>
        <ModalFooter gap={4} pb={6}>
          <Button variant={'outline'} w={'100%'} onClick={onClose} borderColor={'gray.200'}>
            cancel
          </Button>
          <CTAButton colorScheme='red' w={'100%'} onClick={onConfirmDeleteDialogClose}>
            delete it
          </CTAButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
