import { customEvent } from '@playful/telemetry';
import React from 'react';

import { useProject } from '../../hooks/useProject';
import { useRouter } from '../../hooks/useRouter';
import { buildUserRoute } from '../../paths';
import { ProjectThumbnailCard, ProjectThumbnailLabel } from './ProjectThumbnailCard';
import type { CardProps } from './types';

export function PreviewThumbLink({ ThumbLinkProps, hoverLabel, ...props }: CardProps) {
  const { projectId, fallbackData } = props;
  const { projectInfo } = useProject({ id: projectId, fallbackData });

  const { asPath } = useRouter();
  if (!projectInfo) return null;

  let linkPropsQuery = {
    playerModal: true,
    from: asPath,
    publishedUrl: '',
    projectId: '',
    userName: '',
    slug: '',
  };

  // If the project has a published URL that means it uses a custom domain
  if (projectInfo.publishedUrl) {
    linkPropsQuery = {
      ...linkPropsQuery,
      publishedUrl: projectInfo.publishedUrl,
      projectId: projectInfo.id,
    };
  } else {
    linkPropsQuery = {
      ...linkPropsQuery,
      userName: projectInfo.ownerName,
      slug: projectInfo.slug,
    };
  }

  return (
    <ProjectThumbnailCard
      hoverLabel={hoverLabel}
      ThumbLinkProps={{
        href: {
          query: linkPropsQuery,
        },
        onClick: () =>
          customEvent('preview-project', {
            project_id: projectInfo.id,
            owner: projectInfo.owner,
            slug: projectInfo.slug,
          }),
        asPath: buildUserRoute(projectInfo.ownerName, projectInfo.slug),
        shallow: true,
        ...ThumbLinkProps,
      }}
      {...props}
    />
  );
}

export function PreviewCard(props: CardProps) {
  const { projectId, fallbackData } = props;
  const { projectInfo } = useProject({ id: projectId, fallbackData });

  if (!projectInfo) return null;

  return (
    <div>
      <PreviewThumbLink {...props} />
      <ProjectThumbnailLabel>{projectInfo.title}</ProjectThumbnailLabel>
    </div>
  );
}
